import styled from 'styled-components';
import { Modal } from 'react-responsive-modal';

import { Typography } from '../../../components/DesignSystemV2/Typography';
import * as React from 'react';
import { colorsV2 } from '../../../style/colors-v2';
import IconNavigationMapsPinLocation from '../icons/IconNavigationMapsPinLocation';
import IconPhonesPhoneCall from '../icons/IconPhonesPhoneCall';
import { CardWithActionBottom, Tag } from '../components/CardWithActionBottom';
import { IconCalendarSchedule } from 'components/Icon/SVGIcons';

import {
  fromScreen,
  toScreen
} from '../../../utils/media-query/responsive.util';
import IconClose from '../../CourseLanding/icons/Close';
import { ScheduleTable, TableWrapper } from '../components/ScheduleTable';
import {
  filterCourseByNameClassAndCenter,
  groupCourseDataByLevel,
  useScheduleData
} from '../hooks/useScheduleData';
import { ResponsiveLayout } from '../../Layout/ResponsiveLayout';
import { Drawer } from '@material-ui/core';

const SectionHeader = styled.div`
  .section-title {
    margin-bottom: 12px;
  }
`;
const SectionContent = styled.div`
  display: grid;
  grid-gap: 16px;

  ${fromScreen(776)} {
    grid-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromScreen(1144)} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const SectionWrapper = styled.div`
  ${SectionHeader} {
    margin-bottom: 24px;
  }
`;

const ItemDescription = styled.li`
  color: ${colorsV2.black60};
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
const ItemDescriptionList = styled.ul`
  padding-inline-start: 18px;
  margin-block-start: 16px;
  margin-block-end: 0;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  background: ${props => props.color || colorsV2.primary100};
  border-radius: 8px;
  width: 100%;

  svg {
    color: ${colorsV2.white100};
    width: 24px;
    height: 24px;
  }

  &:hover {
    background: ${colorsV2.blue80};
  }
`;

const CardContent = styled.div`
  min-height: 220px;
  ${Tag} {
    width: max-content;
    margin-bottom: 8px;
  }

  .card-title {
    margin-bottom: 16px;
  }
`;

const programs = [
  {
    levels: [
      'PRE',
      '4.0',
      '5.0',
      '6.0',
      '6.5',
      '7.0',
      'INT0',
      'INT1',
      'INT2',
      '4.0 Online',
      '5.0 Online',
      '6.0 Online',
      '7.0 Online',
      'Basic 1 - Online',
      '6.5 Online',
      'Online Basic',
      'Online Intermediate',
      'Online Advanced',
      'SINT',
      'WS8'
    ],
    tag: {
      text: 'IELTS',
      color: colorsV2.blue20
    },
    modalTitle: 'Lịch học Khóa IELTS',
    title: 'Khóa IELTS',
    buttonColor: colorsV2.blue100,
    description: (
      <ItemDescriptionList>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            IELTS cơ bản
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            IELTS nâng cao
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            IELTS cấp tốc
          </Typography>
        </ItemDescription>
      </ItemDescriptionList>
    )
  },

  {
    levels: ['AIM 550+', 'AIM 750+'],
    tag: {
      text: 'TOEIC',
      color: colorsV2.blue20
    },
    modalTitle: 'Lịch học Khóa TOEIC',
    title: 'Khóa TOEIC',
    buttonColor: colorsV2.blue100,
    description: (
      <ItemDescriptionList>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            TOEIC aim 550+
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            TOEIC aim 750+
          </Typography>
        </ItemDescription>
      </ItemDescriptionList>
    )
  },

  {
    levels: [
      'S.INT0',
      'S.INT1',
      'S.INT2',
      'S.Fdn',
      'S.Inter',
      'S.Adv',
      'S.Scr'
    ],
    tag: {
      text: 'SAT',
      color: colorsV2.blue20
    },
    modalTitle: 'Lịch học Khóa SAT',
    title: 'Khóa SAT',
    buttonColor: colorsV2.blue100,
    description: (
      <ItemDescriptionList>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            SAT foundation
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            SAT intermediate
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            SAT advanced
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            SAT super scorer
          </Typography>
        </ItemDescription>
      </ItemDescriptionList>
    )
  },

  {
    levels: ['English 101', 'Talk 101', 'PRONUN'],
    tag: {
      text: 'TATQ',
      color: colorsV2.blue20
    },
    modalTitle: 'Lịch học Khóa Tiếng Anh Tổng Quát',
    title: 'Tiếng Anh Tổng Quát',
    buttonColor: colorsV2.blue100,
    description: (
      <ItemDescriptionList>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            Khóa phát âm giọng Mỹ
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            Khóa giao tiếp cơ bản
          </Typography>
        </ItemDescription>
        <ItemDescription>
          <Typography variant="medium/14-20" color={colorsV2.black60}>
            Khóa xây nền Tiếng Anh
          </Typography>
        </ItemDescription>
      </ItemDescriptionList>
    )
  }
];

const useModalController = () => {
  const [isOpen, setOpen] = React.useState(false);

  const openModal = React.useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setOpen(false);
  }, []);

  return { isOpen, openModal, closeModal };
};

/** FOR DESKTOP **/
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    line-height: 0;

    svg {
      width: 32px;
      height: 32px;
      color: ${colorsV2.gray100};
      cursor: pointer;
    }
  }
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${ModalHeader} {
    margin-bottom: 32px;
  }

  ${TableWrapper} {
    overflow: auto;
    .table {
      overflow: initial;
      max-width: initial;
    }
  }
`;

/** FOR MOBILE **/
const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colorsV2.gray5};
  padding: 12px 16px;
  border-bottom: 1px solid #f4f4f6;

  .icon {
    line-height: 0;

    svg {
      width: 24px;
      height: 24px;
      color: ${colorsV2.black100};
      cursor: pointer;
    }
  }
`;
const DrawerButton = styled.button`
  padding: 8px 16px;
  border: 2px solid ${colorsV2.gray20};
  border-radius: 8px;
  background: none;
  cursor: pointer;
`;
const DrawerFooter = styled.div`
  width: 100%;
  border-top: 2px solid #f4f4f6;
  padding: 12px 16px;

  ${DrawerButton} {
    width: 100%;
  }
`;
const DrawerWrapper = styled.div`
  ${DrawerHeader} {
    margin-bottom: 12px;
  }

  ${TableWrapper} {
    padding: 0 16px;
    max-height: 65vh;
    overflow: auto;
    ${toScreen(768)} {
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .table {
      overflow: initial;
      max-width: initial;
    }
    .table-body {
      > div:first-child {
        .table-row.expanded:first-child {
          border-top: 0;
          padding-top: 0;
        }
        .table-row.collapsed:first-child {
          border-top: 0;
          padding-top: 0;
        }
      }
    }
  }
`;

export const ScheduleByProgramSection = () => {
  const { isOpen, openModal, closeModal } = useModalController();
  const [selectedProgram, setSelectedProgram] = React.useState();
  const selectCenter = React.useCallback(
    program => {
      setSelectedProgram(program);
      openModal();
    },
    [openModal]
  );

  const queryData = useScheduleData();
  const data = groupCourseDataByLevel(
    filterCourseByNameClassAndCenter({
      data: queryData.allGoogleSpreadsheetDolLandingPageMarLevelData,
      filter: {
        levels: selectedProgram ? selectedProgram.levels : null
      }
    })
  );

  return (
    <SectionWrapper>
      <SectionHeader>
        <Typography
          className="section-title"
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Lịch học của DOL theo chương trình
        </Typography>
        <Typography variant="regular/16-24" desktopVariant="regular/20-28" v3>
          DOL chuyên cung cấp các khóa học IELTS/ TOEIC/SAT/ TAGT tư duy & thông
          minh & hiệu quả gấp đôi.
        </Typography>
      </SectionHeader>
      <SectionContent>
        {programs.map((center, index) => (
          <CardWithActionBottom
            key={index}
            Content={
              <CardContent>
                <Tag color={center.tag.color}>
                  <Typography
                    variant="semi-bold/12-20"
                    color={colorsV2.blue100}
                  >
                    {center.tag.text}
                  </Typography>
                </Tag>
                <Typography
                  className="card-title"
                  variant="semi-bold/20-28"
                  as="h3"
                >
                  {center.title}
                </Typography>
                {center.description}
              </CardContent>
            }
            Action={
              <Button
                color={center.buttonColor}
                onClick={() => selectCenter(center)}
              >
                <IconCalendarSchedule />
                <Typography variant="semi-bold/14" color={colorsV2.white100}>
                  Xem lịch học
                </Typography>
              </Button>
            }
          />
        ))}
      </SectionContent>

      <ResponsiveLayout
        breakpointWithComponents={[
          {
            breakpoint: 768,
            renderComponent: (
              <Drawer anchor="bottom" open={isOpen} onClose={closeModal}>
                <DrawerWrapper>
                  <DrawerHeader>
                    <Typography variant="semi-bold/20-28">
                      {selectedProgram && selectedProgram.modalTitle}
                    </Typography>
                    <div onClick={closeModal} className="icon">
                      <IconClose />
                    </div>
                  </DrawerHeader>
                  <ScheduleTable data={data} />
                  <DrawerFooter>
                    <DrawerButton onClick={closeModal}>
                      <Typography variant="semi-bold/14-24">Đóng</Typography>
                    </DrawerButton>
                  </DrawerFooter>
                </DrawerWrapper>
              </Drawer>
            )
          },
          {
            breakpoint: 769,
            ssrDefault: true,
            renderComponent: (
              <Modal
                blockScroll={false}
                showCloseIcon={false}
                closeOnOverlayClick={true}
                closeOnEsc={false}
                onClose={closeModal}
                open={isOpen}
                styles={{
                  overlay: {
                    padding: 60
                  },
                  modal: {
                    maxHeight: '100%',
                    maxWidth: '100%',
                    display: 'flex',
                    padding: 24,
                    borderRadius: 8
                  }
                }}
              >
                <ModalWrapper>
                  <ModalHeader>
                    <Typography variant="semi-bold/24-30">
                      {selectedProgram && selectedProgram.modalTitle}
                    </Typography>
                    <div onClick={closeModal} className="icon">
                      <IconClose />
                    </div>
                  </ModalHeader>
                  <ScheduleTable data={data} />
                </ModalWrapper>
              </Modal>
            )
          }
        ]}
      />
    </SectionWrapper>
  );
};

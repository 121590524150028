import * as React from 'react';

import cl from 'classnames';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { colorsV2 } from 'style/colors-v2';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';
import IconChevronUp from '../icons/IconChevronUp';
import IconContentSmile from '../icons/IconContentSmile';
import IconInterfaceCalendar from '../icons/IconInterfaceCalendar';
import Tag from '../Tag';

/* eslint-disable react/prop-types */
const TAG_COLORS = [
  '#F2AE9D',
  '#A6C7E4',
  '#BAB4DD',
  '#AECFBB',
  '#FFD6A5',
  '#8F92A1',
  '#FF991F',
  '#DE350B',
  '#358756',
  '#2074BB',
  '#5243AA'
];

const IconChevronDown = styled(IconChevronUp)`
  transform: rotate(0deg);
`;

const StyledLink = styled(Link)`
  color: ${colorsV2.blue100};
  text-decoration: underline;
`;

export const TableWrapper = styled.div`
  .row {
    display: flex;

    &.disabled {
      color: #8f92a1;

      .tag {
        color: #ffffff;
      }
    }

    &.bordered {
      border-bottom: 1px solid #d2d3d9;
    }
  }

  .bg-grey {
    background-color: #f4f4f6;
  }

  .table {
    overflow: auto;
    max-width: 100vw;

    .t-course {
      min-width: 282px;
    }

    .t-course-mobile-name {
      white-space: nowrap;
    }

    .t-course-mobile-duration {
      font-size: 14px;
      line-height: 20px;
      flex: 1;
    }

    .t-class {
    }

    .t-class-opening {
    }

    .t-class-session {
    }

    .t-class-duration {
    }

    .table-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d2d3d9;

      .th {
        display: flex;
        align-items: center;
        padding: 8px;

        .th-icon {
          font-size: 16px;
          line-height: 0;
          margin-right: 6px;
        }

        .th-text {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0px;
        }
      }
    }

    .table-body {
      .row-class {
        height: 100%;

        > .row {
          min-height: 43px;
          height: 100%;
        }
      }

      .table-row {
        display: flex;
        border-bottom: 1px solid #d2d3d9;
        @media ${deviceBreakpoints.mobile} {
          border-bottom: none;
        }

        &.is-one-row {
          height: 100%;
        }

        &.expanded,
        &.collapsed {
          &:first-child {
            border-top: 1px solid ${colorsV2.gray40};
            padding-top: 16px;
          }

          &:last-child {
            padding-bottom: 16px;
          }
        }

        &.collapsed {
          .t-course {
            flex: 1;
          }

          .row-icon {
            svg {
              transform: rotate(180deg);
            }
          }

          .row-content {
            display: grid;
            grid-gap: 8px;
            grid-auto-flow: column;
            font-size: 14px;
            line-height: 20px;

            .cell-text {
              margin-bottom: 0;
            }
          }

          .table-content {
            display: none;
          }
        }

        .row-icon {
          font-size: 24px;
          display: grid;
          place-items: center;

          svg {
            transition: all 0.3s ease;
          }
        }

        .table-cell {
          padding: 8px;

          .row-icon {
            margin-right: 12px;
            place-items: start;
          }

          .cell-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;

            &.bold {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .table {
    .table-row {
      justify-content: space-between;

      .table-content {
        flex: 1;
      }

      .table-cell {
        padding: 0;
      }

      .t-class {
        border-bottom: none;
      }

      .row-class {
        > .row {
          justify-content: flex-end;
        }
      }

      .t-class-opening {
        border-bottom: none;
      }

      .t-class-session {
        border-bottom: none;
      }

      .t-class-duration {
        border-bottom: none;
      }
    }
  }

  .table {
    &.right-shadow {
      &:after {
        content: '';
        width: 8px;
        position: absolute;
        pointer-events: none;
        top: 0px;
        height: calc(100% - 24px);
        background: linear-gradient(
          to right,
          rgba(99, 114, 130, 0) 0px,
          rgba(9, 30, 66, 0.13) 100%
        );
        left: calc(100% - 8px);
        display: none;
      }
    }

    &.left-shadow {
      &:before {
        content: '';
        width: 8px;
        position: absolute;
        pointer-events: none;
        top: 0px;
        height: calc(100% - 24px);
        background: linear-gradient(
          to left,
          rgba(99, 114, 130, 0) 0px,
          rgba(9, 30, 66, 0.13) 100%
        );
        left: 0;
        display: none;
      }
    }
  }

  @media ${deviceBreakpoints.md} {
    .table {
      &.right-shadow {
        &:after {
          display: block;
        }
      }

      &.left-shadow {
        &:before {
          display: block;
        }
      }
      .table-body {
        .table-row {
          &.expanded,
          &.collapsed {
            &:first-child {
              border-top: 1px solid ${colorsV2.gray40};
              padding-top: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }

          .table-cell {
            padding: 8px;
          }
        }
      }

      .t-class {
        min-width: 156px;

        .tag {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .t-class-opening {
        min-width: 221px;

        .tag {
          margin-right: 8px;
        }
      }

      .t-class-session {
        min-width: 129px;
      }

      .t-class-duration {
        min-width: 221px;
      }

      .table-row {
        .row-class {
          flex: 1;

          > .row {
            justify-content: flex-start;

            &:hover {
              background-color: #e9f1f8;
            }
          }
        }
      }
    }
  }

  @media ${deviceBreakpoints.lg} {
    .table {
      &.right-shadow {
        &:after {
          display: none;
        }
      }

      &.left-shadow {
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const ScheduleTable = ({ data }) => {
  const [showLeftShadow, setShowLeftShadow] = React.useState(false);
  const [showRightShadow, setShowRightShadow] = React.useState(true);
  const [rowCollapseIndexes, setRowCollapseIndexes] = React.useState([]);

  return (
    <TableWrapper>
      <div
        onScroll={e => {
          try {
            const target = e.target;
            const maxWidth =
              target.scrollWidth - target.getBoundingClientRect().width;
            setShowRightShadow(target.scrollLeft < maxWidth - 10);
            setShowLeftShadow(target.scrollLeft > 0);
          } catch (ex) {
            console.error(ex);
          }
        }}
        className={cl('table', {
          'right-shadow': showRightShadow,
          'left-shadow': showLeftShadow
        })}
      >
        <div className="table-header tablet-only desktop-only d-flex">
          <div className="th t-course tablet-only desktop-only">
            <div className="th-text">Các khóa</div>
          </div>
          <div className="th t-class">
            <div className="th-icon">
              <IconContentSmile />
            </div>
            <div className="th-text">Lớp</div>
          </div>
          <div className="th t-class-opening">
            <div className="th-icon">
              <IconInterfaceCalendar />
            </div>
            <div className="th-text">Khai giảng</div>
          </div>
          <div className="th t-class-session">
            <div className="th-text">Buổi</div>
          </div>
          <div className="th t-class-duration">
            <div className="th-text">Giờ</div>
          </div>
          <div className="th t-class-english-center">
            <div className="th-text">Trung tâm</div>
          </div>
        </div>
        <div className="table-body">
          {data.map((rowData, rowIndex) => {
            const { courseName, courseDuration, classLevel } = rowData;
            return (
              <div key={courseName}>
                <div
                  className={cl(
                    'table-row mobile-only d-flex',
                    rowCollapseIndexes.includes(rowIndex)
                      ? 'collapsed'
                      : 'expanded'
                  )}
                  onClick={() => {
                    if (rowCollapseIndexes.includes(rowIndex)) {
                      setRowCollapseIndexes(prevValue =>
                        prevValue.filter(item => item !== rowIndex)
                      );
                    } else {
                      setRowCollapseIndexes(prevValue => [
                        ...prevValue,
                        rowIndex
                      ]);
                    }
                  }}
                >
                  <div className="table-cell row t-course-mobile-name">
                    <div className="cell-text bold">{courseName}</div>
                  </div>
                  <div className="table-cell row-content t-course-mobile-duration">
                    {courseDuration}
                  </div>
                  <div className="row-icon">
                    <IconChevronDown />
                  </div>
                </div>
                <div
                  className={cl(
                    'table-row',
                    'can-expand-collapse',
                    rowCollapseIndexes.includes(rowIndex)
                      ? 'collapsed'
                      : 'expanded'
                  )}
                >
                  <div
                    onClick={() => {
                      if (rowCollapseIndexes.includes(rowIndex)) {
                        setRowCollapseIndexes(prevValue =>
                          prevValue.filter(item => item !== rowIndex)
                        );
                      } else {
                        setRowCollapseIndexes(prevValue => [
                          ...prevValue,
                          rowIndex
                        ]);
                      }
                    }}
                    className={cl([
                      {
                        'bg-grey': rowCollapseIndexes.includes(rowIndex)
                      },
                      'desktop-only tablet-only d-flex t-course table-cell row'
                    ])}
                  >
                    <div className="row-icon">
                      <IconChevronDown />
                    </div>
                    <div className="row-content">
                      <div className="cell-text bold">{courseName}</div>
                      {courseDuration}
                    </div>
                  </div>
                  <div className="table-content">
                    {classLevel.map(rowClass => {
                      const { levelName, tagName, edges } = rowClass;
                      const color = TAG_COLORS[rowIndex % TAG_COLORS.length];
                      return (
                        <div
                          key={levelName}
                          className={cl('table-row', {
                            'is-one-row':
                              classLevel.length === 1 && edges.length === 1
                          })}
                        >
                          <div className="t-class row">
                            <div className="table-cell row">
                              <Tag
                                className="mobile-only"
                                color={color}
                                bold
                                type="filled"
                              >
                                {edges[0].levelDisplayShorten?.replace(
                                  / 8\.0\+/,
                                  ''
                                )}
                              </Tag>
                              <Tag
                                className="desktop-only tablet-only"
                                color={color}
                                bold
                                type="filled"
                              >
                                {edges[0].levelDisplayShorten?.replace(
                                  / 8\.0\+/,
                                  ''
                                )}
                              </Tag>
                            </div>
                          </div>
                          <div className="row-class">
                            {edges.map((rowSchedule, rowScheduleIndex) => {
                              const {
                                dateOpening,
                                dayOfWeek,
                                duration,
                                isFull,
                                isAlmostFull,
                                mobileDateOpening,
                                scheduleBranchName,
                                branchLocationLink
                              } = rowSchedule;
                              return (
                                <div
                                  key={rowScheduleIndex}
                                  className={cl('row', {
                                    disabled: isFull
                                  })}
                                >
                                  <div className="table-cell no-border t-class-opening">
                                    <div className="cell-text">
                                      <span className="desktop-only tablet-only">
                                        {dateOpening}
                                      </span>
                                      <span className="mobile-only">
                                        {mobileDateOpening}
                                      </span>
                                    </div>
                                    {isFull && (
                                      <Tag type="filled" color="#DE350B">
                                        Full
                                      </Tag>
                                    )}
                                    {isAlmostFull && (
                                      <Tag
                                        type="filled"
                                        color={colorsV2.yellow100}
                                        textColor={colorsV2.white100}
                                      >
                                        Gần Full
                                      </Tag>
                                    )}
                                  </div>
                                  <div className="table-cell no-border row t-class-session">
                                    <div className="cell-text">{dayOfWeek}</div>
                                  </div>
                                  <div className="table-cell no-border row t-class-duration">
                                    <div className="cell-text">{duration}</div>
                                  </div>
                                  <div className="table-cell no-border row t-class-english-center">
                                    <div className="cell-text">
                                      {scheduleBranchName}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </TableWrapper>
  );
};
